






















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Notify } from 'notiflix/build/notiflix-notify-aio';

@Component
export default class NumberPad extends Vue {
  @Prop (Number) readonly value!: number;

  output = this.value.toString();
  inputContent(event: MouseEvent) {
    const buttom = event.target as HTMLButtonElement; // 强制指定类型
    const input = buttom.textContent!; // 不会Wie空
    if (this.output.length === 16) {
      return;
    }
    if (this.output === "0") {
      if (input === ".") {
        this.output = "0.";
        return;
      }
      this.output = input;
      return;
    }
    if(this.output.indexOf('.') > -1 && input === ".") {
      return;
    }
    // 小数点后面最多两位
    if (this.output.indexOf('.') > -1 && this.output.split('.')[1].length === 2) {
      return;
    }
    this.output += input;
  }
  remove() {
    if (this.output.length === 1) {
      this.output = "0";
      return;
    }
    // this.output = this.output.substring(0, this.output.length - 1);
    this.output = this.output.slice(0, -1);
  }
  clear() {
    this.output = "0";
  }
  submit(){
    this.$emit("submit", parseFloat(this.output));
  }
  @Watch("output")
  onValueChange(newValue: string, oldValue: string) {
    this.$emit("update:value", parseFloat(newValue));
  }
}
