









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Types extends Vue {
  @Prop(String) readonly value!: string;
  @Prop(String) classPrefix?: string;
  selectType(type: string) { // type: '-' 支出 '+' 收入
    if (type !== "-" && type !== "+") { 
      throw new Error('type must be "-", "+"');
    }
    this.$emit("update:value", type);
  }
}
// 以下是js写法
// export default {
//   name: "Types",
//   data(){
//     return {
//       type: "-" // '-' 支出 '+' 收入
//     }
//   },
//   methods: {
//     selectType(type){ // type: '-' 支出 '+' 收入
//       if (type !== '-' && type !== '+') {
//         throw new Error('type must be "-", "+"')
//       }
//       this.type = type;
//     }
//   }
// };
