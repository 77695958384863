











export default {
    name: "NotFound"
}
