














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

@Component
export default class Tags extends Vue {
  tagList = window.tagList;
  selectedTags: string[] = [];
  toggle(tag: string) {
    if (this.selectedTags.indexOf(tag) >= 0) {
      this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
    } else {
      this.selectedTags.push(tag);
    }
    this.$emit("update:value", this.selectedTags);
  }
  creataTag() {
    Confirm.prompt(
      '创建新标签',
      '请输入标签名称',
      '',
      'Answer',
      'Cancel',
      (clientAnswer) => {
        if (clientAnswer === "" || clientAnswer === null || clientAnswer.length > 18) {
          window.alert("标签名不能为空或 or 长度超过18个字符");
        } else {
          window.createTag(clientAnswer);
        }
      },
      (clientAnswer) => {
        console.log('Cancel');
      },
      {
      },
    );
  }
}
