



































































import dayjs from 'dayjs';
import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CurrentDetails extends Vue {
    @Prop({ required: true }) record!: RecordItem;
    beautifyType(type: string) {
        return type === '-' ? '支出' : '收入';
    }
    beautifyTags(tags: string[]) {
        let tagList = window.tagList;
        let result = [];
        for (let tag of tags) {
            for (let item of tagList) {
                if (item.id === tag) {
                    result.push(item.name);
                }
            }
        }
        return result.join('、');
    }
    beautifyDate(isoString: string | undefined) {
        return dayjs(isoString).format("YYYY-MM-DD");
    }
}
