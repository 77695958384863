








export default {
  props: ["classPrefix"],
  name: "Layout",
};
