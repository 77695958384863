






















import dayjs from "dayjs";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Notes extends Vue {
  @Prop({ default: "" }) readonly value!: string;

  @Prop({ required: true }) fieldName!: string;
  @Prop({}) placeholder?: string;
  @Prop() type?: string;

  onValueChange(value: string) {
    this.$emit("update:value", value);
  }
  x(isoString:string){
    return dayjs(isoString).format("YYYY-MM-DD");
  }
}
